import noScroll from "../modules/noScroll";

const Menu = (function () {
	"use strict";
	const burgerMenu = $(".js-burger");
	const linkToTarget = $(".js-scroll");
	const language = $(".js-language");
	function scroll(target) {
		const top = target.offset().top;

		$("html, body").animate(
			{
				scrollTop: top,
			},
			800
		);
	}

	function replaceTextBtn(btn) {
		const currText = btn.text();
		const replText = btn.data("text");
		btn.text(replText);
		btn.data("text", currText);
	}

	return {
		showActiveLang: function () {
			const activeLang = $(`.js-language[data-lang=${locale}]`);
			language.removeClass("active");
			activeLang.addClass("active");
		},
		showMobileMenu: function () {
			burgerMenu.on("click", function (e) {
				e.preventDefault();
				const _this = $(this);
				const target = $($(this).data("target"));
				target.toggleClass("menu-mobile--active");
				replaceTextBtn(_this);
				burgerMenu.toggleClass("burger--active");
				noScroll.toggle();
			});
		},
		scrollToTarget: function () {
			linkToTarget.on("click", function (e) {
				e.preventDefault();
				noScroll.off();
				const _this = $(this);
				const href: any = _this.attr("href");
				const target = $(href);

				if (target.length) {
					scroll(target);
					$(".menu-mobile").removeClass("menu-mobile--active");
					replaceTextBtn(burgerMenu);
				}
			});
		},
		init: function () {
			Menu.showMobileMenu();
			Menu.scrollToTarget();
			Menu.showActiveLang();
		},
	};
})();

export default Menu;
