import gsap from "gsap";

export enum DirectionEnum {
	LEFT = "-",
	RIGTH = "+",
}

const AnimateMarquees = (function () {
	"use strict";

	return {
		animateMarquee: function (
			marquee: JQuery<HTMLElement>,
			duration: number,
			direction: DirectionEnum
		) {
			const windowWidth = window.innerWidth;
			const marqueesWidth = marquee.outerWidth() || 0;

			const marqueesParent = marquee.parent();

			const nclone = windowWidth / marqueesWidth + 1;

			let inner = marqueesParent.html();

			let parentWidth = `${nclone * marqueesWidth}px`;

			marqueesParent.css({
				width: parentWidth,
			});

			for (let i = 0; i < nclone; i++) {
				inner += marqueesParent.html();
			}

			marqueesParent.html(inner);

			const x = `${direction}=${marqueesWidth}`;

			gsap.to(marqueesParent, {
				duration,
				repeat: -1,
				x,
				modifiers: {
					x: (x) => gsap.utils.wrap(-marqueesWidth, 0, parseFloat(x)) + "px",
				},
				ease: "linear",
				invalidateOnRefresh: true,
			});
		},
		initAnimate: function () {
			//main marquees
			AnimateMarquees.animateMarquee(
				$(".js-bg-text span"),
				150,
				DirectionEnum.RIGTH
			);
			AnimateMarquees.animateMarquee(
				$(".js-banner span"),
				60,
				DirectionEnum.LEFT
			);

			//promo marquees
			AnimateMarquees.animateMarquee(
				$(".promo__label--top span"),
				40,
				DirectionEnum.LEFT
			);
			AnimateMarquees.animateMarquee(
				$(".promo__label--bottom span"),
				40,
				DirectionEnum.RIGTH
			);

			//niches
			AnimateMarquees.animateMarquee(
				$(".niche--1 .niche__bg-text span"),
				10,
				DirectionEnum.LEFT
			);

			AnimateMarquees.animateMarquee(
				$(".niche--2 .niche__bg-text span"),
				10,
				DirectionEnum.LEFT
			);
			AnimateMarquees.animateMarquee(
				$(".niche--3 .niche__bg-text span"),
				10,
				DirectionEnum.LEFT
			);
			AnimateMarquees.animateMarquee(
				$(".niche--4 .niche__bg-text span"),
				10,
				DirectionEnum.LEFT
			);

			AnimateMarquees.animateMarquee(
				$(".niche--5 .niche__bg-text span"),
				10,
				DirectionEnum.LEFT
			);

			AnimateMarquees.animateMarquee(
				$(".niches__bg-text span"),
				20,
				DirectionEnum.LEFT
			);

			AnimateMarquees.animateMarquee(
				$(".niches__bg-text--reverse span"),
				40,
				DirectionEnum.RIGTH
			);

			//offers
			AnimateMarquees.animateMarquee(
				$(".offers__bottom-help span"),
				10,
				DirectionEnum.LEFT
			);
		},
		init: function () {
			AnimateMarquees.initAnimate();
		},
	};
})();

export default AnimateMarquees;
