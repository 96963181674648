const Sliders = (function () {
	"use strict";
	const sliderNishes = $(".js-slider-niches");

	return {
		initSliderNiches: function () {
			(<any>sliderNishes).slick({
				slidesToShow: 1,
				initialSlide: 4,
				dots: false,
				fade: true,
				autoplay: true,
				autoplaySpeed: 4000,
				prevArrow:
					"<button type='button' class='slick-prev'><i class='fico fico-prev-arrow' aria-hidden='true'></i></button>",
				nextArrow:
					"<button type='button' class='slick-next'><i class='fico fico-next-arrow' aria-hidden='true'></i></button>",
			});
		},
		init: function () {
			Sliders.initSliderNiches();
		},
	};
})();

export default Sliders;
