import "slick-carousel";

import AnimateBase from "./modules/AnimateBase";
import AnimateMarquees from "./modules/AnimateMarquees";
import AspectControl from "./modules/AspectControl";
import { LAYOUT } from "./modules/constants";
import Layout from "./modules/Layout";
import Menu from "./modules/Menu";
import Sliders from "./modules/Sliders";

$(function () {
	Layout.layoutHandler({
		onInit: (layout) => {
			if (layout.windowWidth > LAYOUT.mobileScreenWidth) {
				AnimateBase.animateText();
			} else {
				AnimateBase.destroyAnimateText();
			}
		},
		afterResize: (layout) => {
			if (layout.windowWidth > LAYOUT.mobileScreenWidth) {
				AnimateBase.animateText();
			} else {
				AnimateBase.destroyAnimateText();
			}
		},
	});
});

window.addEventListener("load", function () {
	document.querySelector("body")?.classList.remove("loading");

	new AspectControl();

	AnimateMarquees.init();
	AnimateBase.init();
	Sliders.init();
	Menu.init();
});
