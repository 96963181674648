export default class AspectControl {
  /**
   * base aspect value
   */
  baseAspect: number;

  constructor() {
    this.baseAspect = 670 / 1440;
    this.setCurAspect();
    window.addEventListener("resize", () => {
      this.setCurAspect();
    });
  }

  /**
   * set current aspect value to body css variable
   */
  setCurAspect() {
    const currentAspect = window.innerHeight / window.innerWidth;
    const aspect = currentAspect / this.baseAspect;
    document.body.style.setProperty("--aspect", `${aspect > 1 ? 1 : aspect}`);
  }
}
