import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const AnimateBase = (function () {
	"use strict";

	const timelineText = gsap.timeline({ repeat: -1 });

	function animateBoxes(box, from, axis, ease) {
		gsap.to(box, {
			duration: 0.5,
			x: -20,
			y: -20,
			yoyo: true,
			scale: 1,
			opacity: 1,
			ease: "power1.inOut",
			stagger: {
				amount: 1.5,
				grid: [10, 10],
				axis: axis,
				ease: ease,
				from: from,
			},
		});
	}

	return {
		animateSection: function () {
			let sections = gsap.utils.toArray(".niches--desc .niche");
			gsap.to(sections, {
				xPercent: -100 * (sections.length - 1),
				ease: "none",
				scrollTrigger: {
					trigger: ".niches--desc",
					pin: true,
					scrub: 1,
					snap: {
						snapTo: 1 / (sections.length - 1),
						duration: 1,
					},
					end: () => "+=" + $(".niches--desc").width(),
					invalidateOnRefresh: true,
				},
			});
		},
		animateBg: function () {
			animateBoxes(".promo__bg-img", "start", "y", "none");
		},
		animateText: function () {
			timelineText.play();
			const from = {
				opacity: 0.05,
				color: "#F2AE2E",
				duration: 0.5,
			};
			const to: gsap.TweenVars = {
				opacity: 1,
				color: "#EDEDED",
				duration: 2,
			};
			gsap.set(
				[
					".statistics__item--1",
					".statistics__item--2",
					".statistics__item--3",
					".statistics__item--4",
				],
				{ opacity: 0.05, color: "#F2AE2E" }
			);

			timelineText
				.to([".statistics__item--1"], to, "start1")
				.to([".statistics__item--2"], to, "start2")
				.to([".statistics__item--1"], from, "start2")
				.to([".statistics__item--3"], to, "start3")
				.to([".statistics__item--2"], from, "start3")
				.to([".statistics__item--4"], to, "start4")
				.to([".statistics__item--3"], from, "start4");
		},
		destroyAnimateText: function () {
			timelineText.pause();
		},
		animateCircle: function () {
			gsap.to(".js-circle", {
				rotate: 360,
				duration: 22,
				repeat: -1,
				ease: "linear",
			});
		},
		animateList: function () {
			const listNodes = $(".list");
			const fromText: gsap.TweenVars = {
				color: "#F2AE2E",
				stagger: 3,
			};
			const toText: gsap.TweenVars = {
				color: "#EDEDED",
				stagger: 3,
			};

			const fromIcon: gsap.TweenVars = {
				opacity: 1,
				scale: 1.3,
				stagger: 3,
			};
			const toIcon: gsap.TweenVars = {
				opacity: 0.4,
				scale: 1,
				stagger: 3,
			};

			for (let i = 0; i < listNodes.length; i++) {
				const list = $(listNodes[i]);
				const listTexts = list.find(".list__text");
				const listIcons = list.find(".fico");
				gsap
					.timeline({ repeat: -1 })
					.to(listTexts, fromText)
					.to(listIcons, fromIcon, "<")
					.to(
						listTexts,
						{
							...toText,
							delay: 3,
						},
						"<"
					)
					.to(listIcons, toIcon, "<");
			}
		},
		animateBgText: function () {
			const lettersNodes = $(".letters");

			const scrollTrigger = [
				{
					trigger: ".prizes",
					scrub: 1,
					start: "top center+=20%",
					end: "bottom center+=10%",
				},
				{
					trigger: ".advantages",
					scrub: 1,
					start: "top center+=50%",
					end: "bottom center+=30%",
				},
			];

			for (let i = 0; i < lettersNodes.length; i++) {
				const lettersBlock = $(lettersNodes[i]);
				const rows = lettersBlock.find(".letters__row");
				gsap.to(rows, {
					ease: "back.out(1.4)",
					y: 0,
					scrollTrigger: scrollTrigger[i],
				});

				gsap.to(rows, {
					scale: 1,
					scrollTrigger: scrollTrigger[i],
					stagger: {
						each: 0.1,
					},
				});

				gsap.to(rows, {
					ease: "power4.out",
					duration: 0.5,
					opacity: 1,
					stagger: {
						each: 0.1,
					},
					scrollTrigger: scrollTrigger[i],
				});
			}
		},
		init: function () {
			AnimateBase.animateSection();
			AnimateBase.animateCircle();
			AnimateBase.animateBg();
			AnimateBase.animateList();
			AnimateBase.animateBgText();
		},
	};
})();

export default AnimateBase;
